import React, { useEffect, useState } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Button, Table, Modal, Form as AntForm, Input } from 'antd';
import { modes, formats } from '@comall-backend-builder/types';
import { TypesManager, services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { COUPON_STATUS_OPTIONS } from '@/constants';

enum COUPON_TYPE {
    /**
     * 运费券
     */
    SHIPPING = 'shipping',
    /**
     * 优惠券
     */
    DISCOUNT = 'discount',
}

const columnsBase = [
    {
        title: language.getText('couponManage.couponCenter.columnsBase.name'),
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: language.getText('couponManage.couponCenter.viewData.effectiveStartTime'),
        dataIndex: 'effectStartTime',
        key: 'effectStartTime',
    },
    {
        title: language.getText('couponManage.couponCenter.viewData.effectiveEndTime'),
        dataIndex: 'effectEndTime',
        key: 'effectEndTime',
    },
    {
        title: language.getText('couponManage.couponCenter.columnsBase.reliefType'),
        dataIndex: 'reliefType',
        key: 'reliefType',
    },
    {
        title: language.getText('couponManage.couponCenter.columnsBase.provideType'),
        dataIndex: 'provideType',
        key: 'provideType',
    },
    {
        title: language.getText('couponManage.couponCenter.columnsBase.applyType'),
        dataIndex: 'applyType',
        key: 'applyType',
    },
    {
        title: language.getText('couponManage.couponCenter.columnsBase.stores'),
        dataIndex: 'stores',
        key: 'stores',
    },
    {
        title: language.getText('couponManage.couponCenter.columnsBase.status'),
        dataIndex: 'status',
        key: 'status',
    },
];

const reliefTypeMap = new Map([
    [1, language.getText('couponManage.couponCenter.columnsBase.discount')],
    [2, language.getText('couponManage.couponCenter.columnsBase.reduced')],
]);

const provideTypeMap = new Map([
    [1, language.getText('couponManage.couponCenter.columnsBase.general')],
    [2, language.getText('couponManage.couponCenter.columnsBase.issued')],
]);

const applyTypeMap = new Map([
    [1, language.getText('couponManage.couponCenter.columnsBase.allProducts')],
    [2, language.getText('couponManage.couponCenter.columnsBase.designatedProducts')],
    [3, language.getText('couponManage.couponCenter.columnsBase.designatedBrands')],
    [4, language.getText('couponManage.couponCenter.columnsBase.designationCriteria')],
    [5, language.getText('couponManage.couponCenter.columnsBase.specifyVirtual')],
]);

const EditableContext = React.createContext({});

const EditableCell = (props: any) => {
    const renderCellWrapper = (dataIndex: string, record: any, children: any) => {
        switch (dataIndex) {
            case 'stores':
                return TypesManager.get('array.onlineStoreGroup').getDisplayComponent(
                    get(record, `${dataIndex}`),
                    ''
                );
            case 'provideType':
                return provideTypeMap.get(get(record, `${dataIndex}`));
            case 'reliefType':
                return reliefTypeMap.get(get(record, `${dataIndex}`));

            case 'applyType':
                return applyTypeMap.get(get(record, `${dataIndex}`));
            case 'status':
                return TypesManager.get('string.status').getDisplayComponent(
                    get(record, `${dataIndex}`),
                    {
                        statusConfig: COUPON_STATUS_OPTIONS,
                    }
                );
            default:
                return children;
        }
    };

    const renderCell = (_params: any) => {
        const { dataIndex, record, children, ...restProps } = props;

        return <td {...restProps}>{renderCellWrapper(dataIndex, record, children)}</td>;
    };

    return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
};

const initPage = {
    current: 1,
    pageSize: 10,
    total: 0,
};

const CouponCenterItems = React.forwardRef((props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [couponType, setCouponType] = useState<COUPON_TYPE.SHIPPING | COUPON_TYPE.DISCOUNT>(
        COUPON_TYPE.DISCOUNT
    );
    const [couponList, setCouponList] = useState<any[]>([]);
    const [shippingCouponList, setShippingCouponList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState(initPage);
    const [vouchers, setVouchers] = useState<string>('');

    const { entity } = props;
    const filterColumns = columnsBase
        .filter((col: any) => col.dataIndex !== 'provideType')
        .filter((col: any) => col.dataIndex !== 'status');

    const columns = filterColumns.map((col: any) => {
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    const modalColumns = columnsBase.map((col: any) => {
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }

        if (props.value) {
            setDataSource(props.value);
            props.onChange(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const getData = (
        type: COUPON_TYPE.DISCOUNT | COUPON_TYPE.SHIPPING,
        page: number,
        name?: string
    ) => {
        setLoading(true);

        const date = new Date();
        const Y = date.getFullYear() + '-';
        const M =
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        const D =
            date
                .getDate()
                .toString()
                .padStart(2, '0') + ' ';
        const h =
            date
                .getHours()
                .toString()
                .padStart(2, '0') + ':';
        const m =
            date
                .getMinutes()
                .toString()
                .padStart(2, '0') + ':';
        const s = date
            .getSeconds()
            .toString()
            .padStart(2, '0');

        services.api
            .get(
                {
                    page: page,
                    perPage: pagination.pageSize,
                    couponType: type === COUPON_TYPE.DISCOUNT ? 1 : 2,
                    storeIds: entity.fields.storeIds,
                    enable: true,
                    effectEndTime: Y + M + D + h + m + s,
                    provideType: 1,
                    name,
                },
                {
                    apiPath:
                        type === COUPON_TYPE.DISCOUNT
                            ? '/dc-ecoupon/admin/coupons'
                            : '/dc-ecoupon/admin/freight/coupons',
                }
            )
            .then((res: any) => {
                if (type === COUPON_TYPE.DISCOUNT) {
                    setCouponList(res.result);
                } else {
                    setShippingCouponList(res.result);
                }
                setPagination((val) => ({
                    ...val,
                    current: page,
                    total: res.totalNum,
                }));
            })
            .catch(services.errorHandle)
            .finally(() => {
                setLoading(false);
            });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRows(selectedRows as []);
        },
    };

    const hanldeClick = () => {
        setDataSource(selectedRows);
        props.onChange(selectedRows);
        setVisible(false);
    };

    const changePagination = (e: any) => {
        setPagination((val) => {
            getData(couponType, e.current);
            return {
                ...val,
                current: e.current,
            };
        });
    };

    const getAddForm = () => {
        return (
            <AntForm layout='inline' style={{ marginBottom: 15 }}>
                <AntForm.Item
                    label={language.getText('couponManage.couponCenter.columnsBase.searchTitle')}
                >
                    <Input
                        style={{ width: '170px', marginRight: '15px' }}
                        placeholder={language.getText(
                            'couponManage.couponCenter.columnsBase.searchPlaceholder'
                        )}
                        value={vouchers}
                        onChange={(e) => {
                            setVouchers(e.target.value);
                        }}
                    />
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type='primary'
                        onClick={() => {
                            getData(couponType, 1, vouchers);
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        {language.getText('salesPromotion.eventMarketing.query')}
                    </Button>
                    <Button
                        onClick={() => {
                            setVouchers('');
                        }}
                    >
                        {language.getText('salesPromotion.eventMarketing.reset')}
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    };

    return (
        <EditableContext.Provider value={{}}>
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                }}
            >
                <Button
                    type='primary'
                    style={{ marginBottom: '8px' }}
                    onClick={async () => {
                        setVisible(true);
                        setCouponType(COUPON_TYPE.DISCOUNT);
                        await getData(COUPON_TYPE.DISCOUNT, initPage.current);
                    }}
                    disabled={entity.fields ? !entity.fields.storeIds : true}
                >
                    {language.getText('couponManage.couponCenter.selectCoupon')}
                </Button>
                <Privilege path='coupon.center.freight_coupon_view'>
                    <Button
                        type='primary'
                        style={{ marginBottom: '8px' }}
                        onClick={async () => {
                            setVisible(true);
                            setCouponType(COUPON_TYPE.SHIPPING);
                            await getData(COUPON_TYPE.SHIPPING, initPage.current);
                        }}
                        disabled={entity.fields ? !entity.fields.storeIds : true}
                    >
                        {language.getText('couponManage.couponCenter.selectShippingCoupon')}
                    </Button>
                </Privilege>
            </div>
            {!isEmpty(dataSource) && (
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    scroll={{ x: 'fit-content' }}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(_, index) => `${index}`}
                    pagination={false}
                />
            )}
            <Modal
                title={
                    couponType === 'discount'
                        ? language.getText('couponManage.couponCenter.columnsBase.coupons')
                        : language.getText('couponManage.couponCenter.columnsBase.shippingCoupon')
                }
                visible={visible}
                width={1000}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={hanldeClick}
            >
                {getAddForm()}
                <Table
                    loading={loading}
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    scroll={{ x: 'fit-content' }}
                    dataSource={couponType === 'shipping' ? shippingCouponList : couponList}
                    columns={modalColumns}
                    rowKey='id'
                    pagination={pagination}
                    rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                    }}
                    onChange={changePagination}
                />
            </Modal>
        </EditableContext.Provider>
    );
});

export class CouponCenterItemsMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        const props = {
            ...controlInfo,
        };
        return <CouponCenterItems {...props} />;
    }
}

export class CouponCenterItemsFormat extends formats.StringFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, _value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any[]) {
        return { [key]: value[0].id };
    }
}
