/*
 * @Author: 朱璐
 * @Date: 2022-10-14 17:07:06
 * @Description: 会员资料详情
 */

import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import {
    COUPON_DATA_STATUS_OPTIONS,
    FAMILYSIZE_OPTIONS,
    SELECTED_DEFAULT_OPTIONS,
} from '@/constants';
export const config: Config = {
    entities: {
        membershipDetail: {
            apiPath: '/loader/dc-user/admin/members',
            properties: {
                id: { type: 'string', displayName: '<<membership.id>>' },
                memberId: { type: 'string', displayName: '<<membership.id>>' },
                regTimeStr: {
                    type: 'string',
                    displayName: '<<membership.createTime>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<membership.email>>',
                },
                levelName: {
                    type: 'string',
                    displayName: '<<membership.level>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<membership.firstName>>',
                },
                phone: {
                    type: 'string',
                    displayName: '<<membership.mobile>>',
                },
                point: {
                    type: 'string',
                    displayName: '<<membership.point>>',
                },
                surname: {
                    type: 'string',
                    displayName: '<<membership.lastName>>',
                },
                inviteCode: {
                    type: 'string',
                    displayName: '<<membership.inviteCode>>',
                },
                memberNo: {
                    type: 'string',
                    displayName: '<<membership.memberNo>>',
                },
                storeName: {
                    type: 'string',
                    displayName: '<<membership.storeName>>',
                },
                birthday: {
                    type: 'string',
                    displayName: '<<membership.birthday>>',
                },
                couponCount: {
                    type: 'string',
                    displayName: '<<membership.coupon>>',
                },
                familySize: {
                    type: 'string.options.select',
                    options: FAMILYSIZE_OPTIONS,
                    displayName: '<<membership.familySize>>',
                },
                remark: {
                    type: 'string.text.paragraph',
                    displayName: '<<membership.remark>>',
                },
                lastModifiedTime: {
                    type: 'string',
                    displayName: '<<membership.lastModifiedTime>>',
                },
                sourceSystem: {
                    type: 'string',
                    displayName: '<<membership.sourceSystem>>',
                },
                source: {
                    type: 'string',
                    displayName: '<<membership.source>>',
                },
                checkEmail: {
                    type: 'string',
                    displayName: '<<membership.checkEmail>>',
                },
                isPush: {
                    type: 'string.options.radio',
                    options: SELECTED_DEFAULT_OPTIONS,
                    displayName: '<<membership.isPush>>',
                },
                isCheck: {
                    type: 'string.options.radio',
                    options: SELECTED_DEFAULT_OPTIONS,
                    displayName: '<<membership.isCheck>>',
                },
                memberType: {
                    type: 'string.options.radio',
                    options: SELECTED_DEFAULT_OPTIONS,
                    displayName: '<<membership.memberType>>',
                },
                referrer: {
                    type: 'string',
                    controlConfig: {
                        maxLength: 100,
                    },
                    displayName: '<<membership.referrer>>',
                },
            },
        },
        membershipOrderList: {
            apiPath: '/loader/dc-order/admin/orders',
            filters: {
                organizationId: { type: 'string' },
                keyword: { type: 'string' },
                storeId: { type: 'string' },
            },
        },
        membershipPointList: {
            apiPath: '/loader/dc-user/admin/members/getMemberPointList',
            properties: {
                id: { type: 'string' },
                orderNo: { type: 'string', displayName: '<<membership.pointList.orderNo>>' },
                point: { type: 'string', displayName: '<<membership.pointList.point>>' },
                reason: { type: 'string', displayName: '<<membership.pointList.reason>>' },
                storeName: {
                    type: 'string',
                    displayName: '<<membership.pointList.storeName>>',
                },
                timeStr: { type: 'string', displayName: '<<membership.pointList.timeStr>>' },
            },
            filters: {
                time: {
                    type: 'object.dateTimeRange',
                    displayName: '<<membership.pointList.time>>',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                },
                onlineStoreId: {
                    type: 'string.options.select',
                    displayName: '<<membership.pointList.storeName>>',
                },
            },
        },
        membershipCouponList: {
            apiPath: '/loader/dc-user/admin/members/getMemberCouponList',
            properties: {
                id: { type: 'string' },
                couponNum: {
                    type: 'string',
                    displayName: '<<membership.couponList.couponNo>>',
                },
                status: {
                    type: 'string.options.select',
                    options: COUPON_DATA_STATUS_OPTIONS,
                    displayName: '<<membership.couponList.status>>',
                },

                drawTime: {
                    type: 'string',
                    displayName: '<<membership.couponList.receiveTimeStr>>',
                },
                useTime: {
                    type: 'string',
                    displayName: '<<membership.couponList.usedTimeStr>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<membership.couponList.overdueTimeStr>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<membership.couponList.couponName>>',
                },
                couponTypeName: {
                    type: 'string',
                    displayName: '<<membership.couponList.couponTypeName>>',
                },
            },
            filters: {
                number: {
                    type: 'string',
                    displayName: '<<membership.couponList.couponNo>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<membership.couponList.status>>',
                    options: COUPON_DATA_STATUS_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
        membershipCashCouponList: {
            apiPath: '/loader/dc-ecoupon/admin/vouchers/findMemeberVoucherPage',
            properties: {
                id: { type: 'string' },
                voucherNum: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.cashCouponNo>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.amount>>',
                },
                effectStartTime: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.startTimeStr>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.endTimeStr>>',
                },
                balance: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.remainingAmount>>',
                },
                distributionPlan: {
                    type: 'string',
                    displayName: '<<membership.cashCouponList.distributionPlan>>',
                },
            },
            filters: {
                voucherNum: {
                    displayName: '<<membership.cashCouponList.cashCouponNo>>',
                    type: 'string',
                },
            },
        },
    },
    components: {
        membershipDetailPage: {
            ...createPageLayout([{ component: 'membershipDetail' }]),
        },
        membershipDetail: {
            component: 'Tabs',
            items: [
                {
                    title: '个人信息',
                    content: {
                        component: 'MembershipDetailInfo',
                    },
                },
                {
                    title: '积分明细',
                    content: {
                        component: 'MembershipPoint',
                    },
                    privilege: {
                        path: 'member.member_view.view_point',
                        level: 'full',
                    },
                },
                {
                    title: '优惠券',
                    content: {
                        component: 'MembershipCouponList',
                    },
                    privilege: {
                        path: 'member.member_view.view_coupon',
                        level: 'full',
                    },
                },
                {
                    title: '代金券',
                    content: {
                        component: 'MembershipCashCouponList',
                    },
                    privilege: {
                        path: 'member.member_view.findMemeberVoucherPage',
                        level: 'full',
                    },
                },
            ],
        },
        MembershipPointList: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'membershipPointList',
            items: [
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'time',
                        },
                        {
                            property: 'onlineStoreId',
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: [
                        {
                            property: 'timeStr',
                        },
                        {
                            property: 'storeName',
                        },
                        {
                            property: 'point',
                        },
                        {
                            property: 'reason',
                        },
                        {
                            property: 'orderNo',
                        },
                    ],
                },
            ],
        },
        MembershipDetailInfo: {
            entity: 'membershipDetail',
            entities: [
                {
                    name: 'membershipDetail',
                    entityName: 'membershipDetail',
                },
                {
                    name: 'membershipOrderList',
                    entityName: 'membershipOrderList',
                },
            ],
            component: 'MembershipBaseInfo',
        },
        MembershipCouponList: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'membershipCouponList',
            items: [
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'number',
                        },
                        {
                            property: 'status',
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: [
                        {
                            property: 'couponName',
                        },
                        {
                            property: 'couponTypeName',
                        },
                        {
                            property: 'couponNum',
                        },
                        {
                            property: 'status',
                        },
                        {
                            property: 'drawTime',
                        },
                        {
                            property: 'useTime',
                        },
                        {
                            property: 'effectEndTime',
                        },
                    ],
                },
            ],
        },
        MembershipCashCouponList: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'membershipCashCouponList',
            items: [
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'voucherNum',
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: [
                        {
                            property: 'voucherNum',
                        },
                        {
                            property: 'totalAmount',
                        },
                        {
                            property: 'effectStartTime',
                        },
                        {
                            property: 'effectEndTime',
                        },
                        {
                            property: 'balance',
                        },
                        {
                            property: 'distributionPlan',
                        },
                    ],
                },
            ],
        },
    },
};
