import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash-es';
import get from 'lodash/get';
import { APPLY_TYPE_VALUES, COUPON_TYPE_VALUES } from '@/constants/coupon-manage';
import { languageDataToFields, fieldsToLanguageData } from '@/services/language-utils';
import { dataPropValueToStr } from '@/services';

/**
 * 表单数据转换为接口数据
 */
function formDataToApiData(data: AnyObject) {
    data.effectStartTime = data.effectTime.start;
    data.effectEndTime = data.effectTime.end;
    data.drawNum = data.drawNum || 0;

    const toString = (v: any) => v?.join(',');

    switch (data.applyType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.applyTarget = data.applyGoods;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.applyTarget = toString(data.applyBrand);
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.applyTarget = toString(data.applyOfflineCategory);
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.applyTarget = toString(data.applyVirtualCategory);
            break;
        case APPLY_TYPE_VALUES.MERCHANT:
            data.applyTarget = toString(data.applyMerchant);
            break;
    }

    switch (data.excludeType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.excludeTarget = data.excludeGoods;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.excludeTarget = toString(data.excludeBrand);
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.excludeTarget = toString(data.excludeOfflineCategory);
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.excludeTarget = toString(data.excludeVirtualCategory);
            break;
        case APPLY_TYPE_VALUES.MERCHANT:
            data.excludeTarget = toString(data.excludeMerchant);
            break;
    }

    data.couponNames = fieldsToLanguageData('couponName', data);
    data.descriptions = fieldsToLanguageData('description', data);

    if (!isEmpty(data.paymentModes)) {
        const [channelId, paymentModeId] = data.paymentModes.split('-');
        data.paymentModes = [{ channelId, paymentModeId }];
    } else {
        data.paymentModes = [];
    }

    // 会员等级
    if (isEmpty(data.memberLevelIdList)) {
        delete data.memberLevelIdList;
    }

    return data;
}

/**
 * 接口数据转换为表单数据
 */
function apiDataToFormData(data: AnyObject) {
    data.effectTime = {
        start: data.effectStartTime,
        end: data.effectEndTime,
    };
    data.storeIds = data.stores.map((item: AnyObject) => item.id);
    data.excludeType = data.excludeType || null;
    data.couponPic = data.couponPic ? [data.couponPic] : undefined;

    dataPropValueToStr(data, [
        'applyType',
        'excludeType',
        'reliefType',
        'provideType',
        'drawType',
        'useType',
        'orderUseType',
        'exclusionType',
    ]);

    const toArray = (v: any) => v?.split(',');

    switch (data.applyType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.applyGoods = data.applyTarget;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.applyBrand = toArray(data.applyTarget);
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.applyOfflineCategory = toArray(data.applyTarget);
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.applyVirtualCategory = toArray(data.applyTarget);
            break;
        case APPLY_TYPE_VALUES.MERCHANT:
            data.applyMerchant = toArray(data.applyTarget);
            break;
    }

    switch (data.excludeType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.excludeGoods = data.excludeTarget;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.excludeBrand = toArray(data.excludeTarget);
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.excludeOfflineCategory = toArray(data.excludeTarget);
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.excludeVirtualCategory = toArray(data.excludeTarget);
            break;
        case APPLY_TYPE_VALUES.MERCHANT:
            data.excludeMerchant = toArray(data.excludeTarget);
            break;
    }

    languageDataToFields('couponName', data, data.couponNames);
    languageDataToFields('description', data, data.descriptions);

    if (!isEmpty(data.paymentModes)) {
        const paymentMode = data.paymentModes[0];
        data.paymentModes = `${paymentMode?.channelId}-${paymentMode?.paymentModeId}`;
    } else {
        data.paymentModes = '';
    }

    // 会员等级
    if (!isEmpty(data.couponMemberLevens)) {
        data.memberLevelIdList = data.couponMemberLevens.map(
            (item: { id: string; name: string }) => item.id
        );
    }

    return data;
}

/**
 * 优惠券管理
 */
export default [
    {
        key: '/discount-coupon',
        loader: {
            get(data: AnyObject = {}, config: AnyObject = {}) {
                if (data.id) {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-ecoupon/admin/coupons/:id',
                        })
                        .then(apiDataToFormData);
                } else {
                    data.couponType = COUPON_TYPE_VALUES.NORMAL;
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-ecoupon/admin/coupons',
                        })
                        .then((data: AnyObject) => {
                            data.result = data.result.map((item: AnyObject) => {
                                dataPropValueToStr(item, ['status']);
                                item.applyTypeDisplay = item.applyType;
                                return item;
                            });
                            return data;
                        });
                }
            },
            post(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponType = COUPON_TYPE_VALUES.NORMAL;
                return services.api.post<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/coupons',
                });
            },
            put(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponType = COUPON_TYPE_VALUES.NORMAL;
                return services.api.put<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/coupons/:id',
                });
            },
        },
    },
    {
        key: '/dc-store/admin/coupons/paymentMode',
        loader: {
            get: async function(params: any, config: any) {
                config.apiPath = '/dc-master-data/admin/payment_mode/list';
                const result: any = await services.api.get(params, config);
                result.forEach((item: any) => {
                    item.id = item.channelId;
                    item.label = item.name;
                    item.children = item.paymentModes.map((child: any) => {
                        return { id: `${item.id}-${child.id}`, name: child.name };
                    });
                });
                return result;
            },
        },
    },
    {
        key: '/discount-coupon/data',
        loader: {
            get(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponId = data.id;
                return services.api
                    .get<AnyObject>(data, {
                        ...config,
                        apiPath: '/dc-ecoupon/admin/coupons/datas',
                    })
                    .then((data) => {
                        data.result = data.result.map((item: AnyObject) => {
                            item.drawStore = get(item, 'drawStore.name');
                            item.useStore = get(item, 'useStore.name');
                            return item;
                        });
                        return data;
                    });
            },
        },
    },
];
