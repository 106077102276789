import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import {
    createPageLayout,
    flexibleFilterForm,
    tableMixin,
    deletePopConfirmConfig,
} from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { MEMBER_PRICE } from '@/configs/pro-table-constants';
import { recordImportLogs } from '@/services';

/**
 * 会员等级会员
 */
export const config: Config = {
    entities: {
        memberLevelMember: {
            apiPath: '/loader/dc-user/admin/memberLevel/level',
            properties: {
                id: { type: 'string' },
                phone: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.mobile>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyTime>>',
                },
                lastName: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastName>>',
                },
                firstName: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.firstName>>',
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyUser>>',
                },
            },
            filters: {
                phone: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.mobile>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.mobile>>',
                    },
                },
            },
        },
    },
    components: {
        memberLevelMember: {
            entity: 'memberLevelMember',
            ...createPageLayout([
                {
                    component: 'memberLevelMemberFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'member.member_level_view.view_level_member_list',
                        level: 'full',
                    },
                },
                {
                    component: 'memberLevelMemberAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_level_view.view_level_member_list',
                        level: 'full',
                    },
                    component: 'memberLevelMemberTable',
                },
            ]),
        },
        memberLevelMemberFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'phone',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.mobilePlaceholder>>',
                    },
                },
            ],
        },
        memberLevelMemberAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<membershipLevel.importMember>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        const id = window.location.hash.replace('#/member-level/member/', '');
                        if (response && id) {
                            recordImportLogs({
                                name: services.language.getText('membershipLevel.importPrice'),
                                apiPath: `/dc-user/admin/memberLevel/level/${id}`,
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_level_view.import_level_member',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'member.member_level_view.import_level_member',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.MEMBER_LEVEL}`,
                },
            ],
        },
        memberLevelMemberTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: MEMBER_PRICE,
            columns: [
                { property: 'phone' },
                { property: 'lastName' },
                { property: 'firstName' },
                { property: 'lastedModifyTime' },
                { property: 'lastedModifyUser' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-user/admin/memberLevel/member/{{row.id}}',
                        },
                        privilege: {
                            path: 'member.member_level_view.delete_level_member',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};
