import React, { useState } from 'react';
import { Privilege } from '@/components/privilege';
import { services } from '@comall-backend-builder/core';
import { Button, message, Modal as AntModal, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import './index.less';

export const OrderBulkDelivery = (props: any) => {
    const { selectedRowKeys, parentProps, batchConfig } = props;
    const [loading, setLoading] = useState(false);
    const [successCount, setSuccessCount] = useState(0);
    const [open, setOpen] = useState(false);

    const confirmHandler = () => {
        if (isEmpty(selectedRowKeys)) {
            message.warn(
                batchConfig.activeKey === 'PACKED'
                    ? language.getText('order.bulkDelivery.warnMsg')
                    : language.getText('order.bulkReceiving.warnMsg')
            );
        } else {
            setOpen(true);
        }
    };

    async function handleOk() {
        try {
            setOpen(false);
            setLoading(true);
            for (const id of selectedRowKeys) {
                await services.api.put(
                    batchConfig.activeKey === 'PACKED' ? { id, expressNo: 1 } : { id },
                    {
                        apiPath: formatUrl(
                            batchConfig.activeKey === 'PACKED'
                                ? API_MAP.ORDER_DELIVER
                                : API_MAP.ORDER_COMPLETED,
                            parentProps.config.type
                        ),
                    }
                );
                setSuccessCount((val) => val + 1);
            }
        } catch (e) {
            errorHandle(e as ErrorInfo);
        } finally {
            parentProps.entity.search();
            parentProps.fetchStatistics();
            setLoading(false);
        }
    }

    const handleCancel = () => {
        setOpen(false);
    };

    if (isEmpty(parentProps.result)) {
        return null;
    }

    return (
        <>
            <Privilege
                path={
                    batchConfig.activeKey === 'PACKED'
                        ? 'order.order.batch_confirm_order'
                        : 'order.order.batch_completed_order'
                }
            >
                <Button type='primary' onClick={confirmHandler} loading={loading}>
                    {batchConfig.activeKey === 'PACKED'
                        ? language.getText('order.bulkDelivery.btnText')
                        : language.getText('order.bulkReceiving.btnText')}
                </Button>
            </Privilege>
            {loading && (
                <div className='order-bulk-delivery-loading'>
                    <Spin
                        spinning={loading}
                        tip={`${language.getText('order.bulkDelivery.tip')}${successCount}/${
                            selectedRowKeys.length
                        }`}
                    />
                </div>
            )}
            <AntModal
                title={
                    batchConfig.activeKey === 'PACKED'
                        ? language.getText('order.bulkDelivery.modalTitle')
                        : language.getText('order.bulkReceiving.modalTitle')
                }
                visible={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
            >
                <div>
                    {batchConfig.activeKey === 'PACKED'
                        ? language.getText('order.bulkDelivery.modalContent')
                        : language.getText('order.bulkReceiving.modalContent')}
                </div>
            </AntModal>
        </>
    );
};
