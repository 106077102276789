import { services } from '@comall-backend-builder/core';
import { APPLY_TYPE_VALUES, COUPON_TYPE_VALUES } from '@/constants/coupon-manage';
import { languageDataToFields, fieldsToLanguageData } from '@/services/language-utils';
import { dataPropValueToStr } from '@/services';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

/**
 * 表单数据转换为接口数据
 */
function formDataToApiData(data: AnyObject) {
    data.effectStartTime = data.effectTime.start;
    data.effectEndTime = data.effectTime.end;
    data.drawNum = data.drawNum || 0;

    switch (data.applyType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.applyTarget = data.applyGoods;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.applyTarget = data.applyBrand;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.applyTarget = data.applyOfflineCategory;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.applyTarget = data.applyVirtualCategory;
            break;
    }

    switch (data.excludeType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.excludeTarget = data.excludeGoods;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.excludeTarget = data.excludeBrand;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.excludeTarget = data.excludeOfflineCategory;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.excludeTarget = data.excludeVirtualCategory;
            break;
    }

    data.couponNames = fieldsToLanguageData('couponName', data);
    data.descriptions = fieldsToLanguageData('description', data);

    // 会员等级
    if (isEmpty(data.memberLevelIdList)) {
        delete data.memberLevelIdList;
    }

    return data;
}

/**
 * 接口数据转换为表单数据
 */
function apiDataToFormData(data: AnyObject) {
    data.effectTime = {
        start: data.effectStartTime,
        end: data.effectEndTime,
    };
    data.storeIds = data.stores.map((item: AnyObject) => item.id);
    data.excludeType = data.excludeType || null;
    data.couponPic = data.couponPic ? [data.couponPic] : undefined;

    // 兼容旧数据，orderType 为 1，自营门店 id 为 1，经讨论可以直接赋值
    if (!data.merchantId) {
        data.merchantId = String(data.orderType);
    }

    dataPropValueToStr(data, [
        'applyType',
        'excludeType',
        'reliefType',
        'provideType',
        'drawType',
        'useType',
        'orderUseType',
        'exclusionType',
    ]);

    switch (data.applyType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.applyGoods = data.applyTarget;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.applyBrand = data.applyTarget;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.applyOfflineCategory = data.applyTarget;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.applyVirtualCategory = data.applyTarget;
            break;
    }

    switch (data.excludeType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.excludeGoods = data.excludeTarget;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.excludeBrand = data.excludeTarget;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.excludeOfflineCategory = data.excludeTarget;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.excludeVirtualCategory = data.excludeTarget;
            break;
    }

    languageDataToFields('couponName', data, data.couponNames);
    languageDataToFields('description', data, data.descriptions);

    // 会员等级
    if (!isEmpty(data.couponMemberLevens)) {
        data.memberLevelIdList = data.couponMemberLevens.map(
            (item: { id: string; name: string }) => item.id
        );
    }

    return data;
}

/**
 * 优惠券管理
 */
export default [
    {
        key: '/freight-coupon',
        loader: {
            get(data: AnyObject = {}, config: AnyObject = {}) {
                if (data.id) {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-ecoupon/admin/coupons/:id',
                        })
                        .then(apiDataToFormData);
                } else {
                    data.couponType = COUPON_TYPE_VALUES.FREIGHT;
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-ecoupon/admin/coupons',
                        })
                        .then((data: AnyObject) => {
                            data.result = data.result.map((item: AnyObject) => {
                                dataPropValueToStr(item, ['status']);
                                item.applyTypeDisplay = item.applyType;
                                return item;
                            });
                            return data;
                        });
                }
            },
            post(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponType = COUPON_TYPE_VALUES.FREIGHT;
                return services.api.post<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/coupons',
                });
            },
            put(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponType = COUPON_TYPE_VALUES.FREIGHT;
                return services.api.put<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/coupons/:id',
                });
            },
        },
    },
    {
        key: '/freight-coupon/data',
        loader: {
            get(data: AnyObject = {}, config: AnyObject = {}) {
                data.couponId = data.id;
                return services.api
                    .get<AnyObject>(data, {
                        ...config,
                        apiPath: '/dc-ecoupon/admin/coupons/datas',
                    })
                    .then((data) => {
                        data.result = data.result.map((item: AnyObject) => {
                            item.drawStore = get(item, 'drawStore.name');
                            item.useStore = get(item, 'useStore.name');
                            return item;
                        });

                        return data;
                    });
            },
        },
    },
];
