import React, { useEffect, useState, useRef } from 'react';
import { formats, modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';

const ViewText = React.forwardRef((props: any) => {
    const [text, setText] = useState('-');
    const { entity } = props;
    const storeIdsRef = useRef('');

    useEffect(() => {
        if (props.value) {
            setText(props.value);
        }
    }, []);

    useAsyncEffect(async () => {
        if (
            entity.fields &&
            entity.fields.storeIds &&
            entity.fields.storeIds !== storeIdsRef.current
        ) {
            const res: any = await services.api.get(
                {},
                {
                    apiPath: `/dc-store/admin/onlineStore/findById/${entity.fields.storeIds}`,
                }
            );
            setText(res.businessType);
            entity.fields && (storeIdsRef.current = entity.fields.storeIds);
        }
    }, [props]);

    useUpdateEffect(() => {
        props.onChange(text);
    }, [text]);

    return <span>{text}</span>;
});

/**
 * 标题类型
 */
export class ViewTextMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getControlComponent(controlInfo: any) {
        return <ViewText {...controlInfo} />;
    }
}

export class ViewTextFormat extends formats.StringFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, _value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: value };
    }
}
