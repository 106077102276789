import React, { PureComponent, Fragment } from 'react';
import { services, Loader } from '@comall-backend-builder/core';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { Modal, Button, Form, Input, message as AntMessage } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { isEmpty, get } from 'lodash';
import { COMPONENT_TYPE } from '@/constants';

const { language, interpolate } = services;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

interface Props extends FormComponentProps, ExtendedParentComponentProps {
    componentType: ComponentType;
    row: { [key: string]: any };
    option?: any;
}

type ComponentType = COMPONENT_TYPE.MENU_BAR_WORDS | string;

const getComponentType = (type: ComponentType) => {
    if (type) {
        return type;
    } else {
        return COMPONENT_TYPE.MENU_BAR_WORDS;
    }
};

const getApiPath = (type: ComponentType) => {
    switch (type) {
        case COMPONENT_TYPE.MENU_BAR_WORDS:
            return '/dc-master-data/admin/homeNavigation';
        case COMPONENT_TYPE.NOTICE_BAR_WORDS:
            return '/dc-master-data/admin/homeFeature';
        default:
            return '/dc-cms-api/admin/hot_words/detail';
    }
};

export class ModalSetMenu extends PureComponent<Props, any> {
    constructor(props: any) {
        super(props);
        const languageKey = getComponentType(this.props.componentType);
        this.state = {
            visible: false,
            searchWords: [{ title: '', url: '' }],
            confirmLoading: false,
            languageKey,
        };
    }
    componentDidMount() {
        this.getMenuDetail();
    }

    getMenuDetail() {
        const { componentType, row, option } = this.props;

        const detailApiPath = getApiPath(componentType);

        Loader.load('get', {
            apiPath: `${detailApiPath}/${row.storeId}?language_iso_code=${option.id}`,
        })
            .then((res: any) => {
                if (!isEmpty(res) && res.length > 0) {
                    this.setState({ searchWords: res });
                } else {
                    this.setState({ searchWords: [{ title: '', url: '' }] });
                }
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ confirmLoading: false });
            });
    }

    toggleModal = (flag: boolean) => () => {
        if (flag) {
            this.getMenuDetail();
        }
        this.setState({ visible: flag }, this.props.form.resetFields);
    };

    handleSubmit = (e: any) => {
        const { row, form, componentType, option } = this.props;

        let apiPath = getApiPath(componentType);

        if (option) {
            apiPath = `${apiPath}/${row.storeId}?language_iso_code=${option.id}`;
        }

        form.validateFields((error: any, values: any) => {
            if (!error) {
                const { confirmLoading } = this.state;
                if (confirmLoading) return;
                this.setState({ confirmLoading: true });

                const params = values.formData.map((item: any) => ({
                    ...item,
                }));

                Loader.load('put', {
                    params,
                    apiPath,
                })
                    .finally(() => {
                        this.setState({ confirmLoading: false });
                    })
                    .then(() => {
                        const { entity, params: entityParams = {} } = this.props;
                        entity && entity.search(entityParams);
                        AntMessage.success(services.language.getText('saveFully'), 1.5);
                        this.toggleModal(false)();
                    })
                    .catch(services.errorHandle);
            }
        });
    };
    onAddTag() {
        const { searchWords } = this.state;
        const nextKeys = searchWords.concat({ title: '', url: '' });
        this.setState({ searchWords: [...nextKeys] });
    }

    onRemoveTag(i: number) {
        const { searchWords } = this.state;
        if (searchWords.length === 1) {
            return;
        }
        const nextKeys = searchWords.filter((_item: any, index: number) => {
            return index !== i;
        });
        this.setState({ searchWords: [...nextKeys] });
    }

    renderItem = () => {
        const { searchWords, languageKey } = this.state;
        const {
            componentType,
            form: { getFieldDecorator, validateFields },
        } = this.props;

        return (
            <Fragment>
                {searchWords.map((item: any, index: number) => {
                    return (
                        <div key={`item-${index}`}>
                            <Form.Item
                                label={interpolate(
                                    language.getText(`designManager.${languageKey}.menu`),
                                    {
                                        key: index + 1,
                                    }
                                )}
                                required={true}
                                {...formItemLayout}
                                style={{ width: '100%' }}
                            >
                                {getFieldDecorator(`formData.${index}.title`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                `designManager.${languageKey}.titleRequire`
                                            ),
                                        },
                                    ],
                                    initialValue: isEmpty(item.title) ? null : item.title,
                                })(
                                    <Input
                                        placeholder={language.getText(
                                            `designManager.${languageKey}.placeholderTitle`
                                        )}
                                        style={{ width: '50%', marginRight: 8 }}
                                        maxLength={
                                            componentType === COMPONENT_TYPE.NOTICE_BAR_WORDS
                                                ? 100
                                                : 20
                                        }
                                        onBlur={validateFields}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} style={{ display: 'none' }}>
                                {getFieldDecorator(`formData.${index}.id`, {
                                    initialValue: isEmpty(item.id) ? 0 : item.id,
                                })(
                                    <Input
                                        style={{ width: '50%', marginRight: 8 }}
                                        maxLength={50}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={interpolate(
                                    language.getText(`designManager.${languageKey}.url`),
                                    {
                                        key: index + 1,
                                    }
                                )}
                                required={false}
                                {...formItemLayout}
                                style={{ width: '100%' }}
                            >
                                {getFieldDecorator(`formData.${index}.url`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [{ required: false }],
                                    initialValue: isEmpty(item.url) ? null : item.url,
                                })(
                                    <Input
                                        placeholder={language.getText(
                                            `designManager.${languageKey}.placeholder`
                                        )}
                                        style={{ width: '50%', marginRight: 8 }}
                                        maxLength={500}
                                        onBlur={validateFields}
                                    />
                                )}
                                <Button
                                    style={
                                        index === searchWords.length - 1 && searchWords.length === 1
                                            ? { display: 'none' }
                                            : undefined
                                    }
                                    type='link'
                                    size='small'
                                    className='remove-tag-button'
                                    onClick={() => this.onRemoveTag(index)}
                                >
                                    {language.getText('delete')}
                                </Button>
                                <Button
                                    type='link'
                                    size='small'
                                    className='add-tag-button'
                                    style={
                                        (!searchWords ||
                                            !searchWords.length ||
                                            index === searchWords.length - 1) &&
                                        searchWords.length < 10
                                            ? undefined
                                            : { visibility: 'hidden' }
                                    }
                                    onClick={() => this.onAddTag()}
                                >
                                    {language.getText('add')}
                                </Button>
                            </Form.Item>
                        </div>
                    );
                })}
            </Fragment>
        );
    };
    render() {
        const { visible, confirmLoading, languageKey } = this.state;
        const { option } = this.props;
        return (
            <Fragment>
                <Button onClick={this.toggleModal(true)} type='link' style={{ marginLeft: 0 }}>
                    {get(option, 'name') ||
                        language.getText(`designManager.${languageKey}.setWords`)}
                </Button>
                <Modal
                    destroyOnClose
                    confirmLoading={confirmLoading}
                    title={`${language.getText(`designManager.${languageKey}.setMenus`)}${
                        option ? `（${get(option, 'name')}）` : ''
                    }`}
                    mask={false}
                    visible={visible}
                    maskClosable={true}
                    okText={services.language.getText('submit')}
                    cancelText={services.language.getText('cancel')}
                    onOk={this.handleSubmit}
                    onCancel={this.toggleModal(false)}
                >
                    {this.renderItem()}
                </Modal>
            </Fragment>
        );
    }
}

export const ModalSetMenuWords = Form.create()(ModalSetMenu);
