import React, { useEffect, useState } from 'react';
import { cloneDeep, get, isEmpty, isEqual, concat, set } from 'lodash';
import {
    Input,
    Button,
    Table,
    Checkbox,
    Button as AntButton,
    Modal as AntModal,
    Form as AntForm,
    InputNumber,
} from 'antd';
import { modes, formats } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

const EditableContext = React.createContext({});

const EditableCell = (props: any) => {
    const renderCell = (_params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange: changeValue,
            editConfig = {},
            ...restProps
        } = props;

        return (
            <td {...restProps}>
                {editable ? (
                    <InputNumber
                        min={1}
                        max={99}
                        {...editConfig}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={get(record, `${dataIndex}`)}
                        onChange={(e) => {
                            if (dataIndex === 'couponNum') {
                                changeValue(index, e, dataIndex, 'coupon');
                            } else {
                                changeValue(index, e, dataIndex, 'other');
                            }
                        }}
                    />
                ) : (
                    children
                )}
            </td>
        );
    };

    return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
};
const contentMap = [
    { key: 'name', name: language.getText('salesPromotion.eventMarketing.couponName') },
    {
        key: 'effectStartTime',
        name: language.getText('salesPromotion.eventMarketing.base.effectiveStartTime'),
    },
    {
        key: 'effectEndTime',
        name: language.getText('salesPromotion.eventMarketing.base.effectiveEndTime'),
    },
    { key: 'couponNum', name: language.getText('salesPromotion.eventMarketing.issuedNumber') },
];

const contentOtherMap = [
    { key: 'name', name: language.getText('salesPromotion.eventMarketing.couponName') },
    { key: 'otherNum', name: language.getText('salesPromotion.eventMarketing.issuedNumber') },
];

const EventMarketingItems = (props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [dataOtherSource, setDataOtherSource] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [couponList, setCouponList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [couponType, setCouponType] = useState<'coupon' | 'other'>('coupon');
    const [integral, setIntegral] = useState<number | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>('');
    const [integralChecked, setIntegralChecked] = useState<boolean>(!isEmpty(props.value?.val));
    const [couponChecked, setCouponChecked] = useState<boolean>(!isEmpty(props.value?.items));
    const [otherChecked, setOtherChecked] = useState<boolean>(!isEmpty(props.value?.other));

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        if (props.value && props.value.items) {
            setDataSource(props.value.items);
        }
        if (props.value && props.value.other) {
            setDataOtherSource(props.value.other);
        }
        if (!isEqual(props.value, dataSource) && props.value && couponType === 'coupon') {
            props.onChange(props.value, props.name);
        }
        if (!isEqual(props.value, dataOtherSource) && props.value && couponType === 'other') {
            props.onChange(props.value, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChange = (index: any, val: any, dataIndex: string, couponType: string) => {
        let { name, value, onChange } = props;
        let nVal = cloneDeep(value);
        if (nVal !== null && !isEmpty(nVal) && couponType === 'coupon') {
            //@ts-ignore
            set(nVal, `items.${index}.${dataIndex}`, val);
        } else if (nVal !== null && !isEmpty(nVal) && couponType === 'other') {
            //@ts-ignore
            set(nVal, `other.${index}.${dataIndex}`, val);
        }
        if (onChange && couponType === 'coupon') {
            onChange(nVal, name);
        } else if (onChange && couponType === 'other') {
            onChange(nVal, name);
        }
    };

    const getCouponList = (type: 'coupon' | 'other', value?: string) => {
        if (
            props.entity.fields &&
            props.entity.fields.storeIds &&
            props.entity.fields.storeIds.length > 0 &&
            type === 'coupon'
        ) {
            setConfirmLoading(true);
            let params = '';
            props.entity.fields.storeIds.forEach((item: any) => {
                params += `storeIds=${item}&`;
            });
            services.api
                .get(
                    { name: value },
                    {
                        apiPath: `/dc-ecoupon/admin/coupons/findByStores?${params.substring(
                            0,
                            params.length - 1
                        )}`,
                    }
                )
                .then((res) => {
                    setCouponList(res as []);
                })
                .catch(services.errorHandle)
                .finally(() => {
                    setConfirmLoading(false);
                });
        } else if (type === 'other') {
            setConfirmLoading(true);
            services.api
                .get(
                    { number: value },
                    {
                        apiPath: '/dc-ecoupon/admin/otherCoupons/findByNumber',
                    }
                )
                .then((res) => {
                    setCouponList(res as []);
                })
                .catch(services.errorHandle)
                .finally(() => {
                    setConfirmLoading(false);
                });
        }
    };

    const n: any[] = contentMap.map((item: any) => ({
        title: item.name,
        dataIndex: item.key,
        editable: item.key === 'couponNum' ? true : false,
        editConfig: {
            maxLength: 100,
        },
    }));
    let columns: any[] = concat(n, [
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (_text: any, _record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        style={{ padding: '0 4px' }}
                        onClick={() => {
                            let nVal: any = cloneDeep(dataSource);
                            nVal.splice(index, 1);
                            props.onChange({ ...props.value, items: nVal }, props.name);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ]).map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });

    const otherN: any[] = contentOtherMap.map((item: any) => ({
        title: item.name,
        dataIndex: item.key,
        editable: item.key === 'otherNum' ? true : false,
        editConfig: {
            maxLength: 100,
        },
    }));
    let otherColumns: any[] = concat(otherN, [
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (_text: any, _record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        style={{ padding: '0 4px' }}
                        onClick={() => {
                            let nVal: any = cloneDeep(dataOtherSource);
                            nVal.splice(index, 1);
                            props.onChange({ ...props.value, other: nVal }, props.name);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ]).map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
                couponType,
            }),
        };
    });

    const couponListColumns =
        couponType === 'coupon'
            ? [
                  {
                      title: language.getText('salesPromotion.eventMarketing.couponName'),
                      dataIndex: 'name',
                      key: 'name',
                  },
                  {
                      title: language.getText(
                          'salesPromotion.eventMarketing.base.effectiveStartTime'
                      ),
                      dataIndex: 'effectStartTime',
                      key: 'effectStartTime',
                  },
                  {
                      title: language.getText(
                          'salesPromotion.eventMarketing.base.effectiveEndTime'
                      ),
                      dataIndex: 'effectEndTime',
                      key: 'effectEndTime',
                  },
              ]
            : [
                  {
                      title: language.getText('salesPromotion.eventMarketing.couponName'),
                      dataIndex: 'name',
                      key: 'name',
                  },
              ];

    const handleCancel = () => {
        setVisible(false);
    };

    const handleComplete = () => {
        setVisible(false);
        if (couponType === 'coupon' && couponChecked) {
            const nVal: any = cloneDeep(dataSource) || [];
            if (selectedRows.length > 0) {
                // @ts-ignore
                const wrapSelectedRows = [{ ...selectedRows[0], couponNum: 1 }];
                props.onChange(
                    { ...props.value, items: nVal.concat([...wrapSelectedRows]) },
                    props.name
                );
            }
        } else if (couponType === 'other' && otherChecked) {
            const nVal: any = cloneDeep(dataOtherSource) || [];
            if (selectedRows.length > 0) {
                // @ts-ignore
                const wrapSelectedRows = [{ ...selectedRows[0], otherNum: 1 }];
                props.onChange(
                    { ...props.value, other: nVal.concat([...wrapSelectedRows]) },
                    props.name
                );
            }
        }
        setSelectedRows([]);
    };

    const onChangeSearchKey = (e: any) => {
        setSearchValue(e.target.value);
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRows(selectedRows as []);
        },
    };

    const getAddForm = () => {
        return (
            <AntForm layout='inline' style={{ marginBottom: 15 }}>
                <AntForm.Item
                    label={
                        couponType === 'coupon'
                            ? language.getText('salesPromotion.eventMarketing.couponName')
                            : language.getText('salesPromotion.eventMarketing.otherName')
                    }
                >
                    <Input
                        value={searchValue}
                        style={{ width: '170px', marginRight: '15px' }}
                        placeholder={
                            couponType === 'coupon'
                                ? language.getText('salesPromotion.eventMarketing.enterCouponName')
                                : language.getText('salesPromotion.eventMarketing.enterOtherName')
                        }
                        onChange={(e) => onChangeSearchKey(e)}
                    />
                </AntForm.Item>
                <AntForm.Item>
                    <AntButton
                        type='primary'
                        onClick={() => {
                            if (searchValue) {
                                getCouponList(couponType, searchValue);
                            }
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        {language.getText('salesPromotion.eventMarketing.query')}
                    </AntButton>
                    <AntButton
                        onClick={() => {
                            setSearchValue('');
                            getCouponList(couponType);
                        }}
                    >
                        {language.getText('salesPromotion.eventMarketing.reset')}
                    </AntButton>
                </AntForm.Item>
            </AntForm>
        );
    };

    return (
        <>
            <EditableContext.Provider value={{}}>
                <div style={{ display: 'flex' }}>
                    <Checkbox
                        checked={integralChecked}
                        onChange={(e) => {
                            setIntegralChecked(e.target.checked);
                            if (e.target.checked) {
                                if (props.value && !isEqual(props.value.val, integral)) {
                                    props.onChange({ ...props.value, val: integral }, props.name);
                                }
                            } else {
                                props.onChange({ ...props.value, val: '' }, props.name);
                            }
                        }}
                    />
                    <span style={{ marginRight: '8px', display: 'block', width: '50px' }}>
                        {language.getText('salesPromotion.eventMarketing.integral')}
                    </span>
                    <InputNumber
                        value={get(props.value, 'val')}
                        placeholder={language.getText('salesPromotion.eventMarketing.pleaseEnter')}
                        min={0}
                        max={99999}
                        precision={0}
                        onChange={(e) => {
                            setIntegral(e);
                            if (integralChecked) {
                                props.onChange({ ...props.value, val: e }, props.name);
                            }
                        }}
                    />
                </div>
                <div>
                    <div>
                        <Checkbox
                            checked={couponChecked}
                            onChange={(e) => {
                                setCouponChecked(e.target.checked);
                                if (e.target.checked) {
                                    if (
                                        couponType === 'coupon' &&
                                        !isEmpty(dataSource) &&
                                        !isEqual(props.value.items, dataSource)
                                    ) {
                                        const nVal: any = cloneDeep(dataSource) || [];
                                        props.onChange(
                                            {
                                                ...props.value,
                                                items: nVal.concat([...selectedRows]),
                                            },
                                            props.name
                                        );
                                    }
                                } else {
                                    props.onChange(
                                        {
                                            ...props.value,
                                            items: [],
                                        },
                                        props.name
                                    );
                                }
                            }}
                        />
                        <span style={{ marginRight: '8px' }}>
                            {language.getText('salesPromotion.eventMarketing.coupons')}
                        </span>
                        <Button
                            type='primary'
                            style={{ marginBottom: '8px' }}
                            onClick={() => {
                                setCouponList([]);
                                setVisible(true);
                                setCouponType(() => {
                                    getCouponList('coupon');
                                    return 'coupon';
                                });
                            }}
                        >
                            {language.getText('salesPromotion.eventMarketing.addCoupons')}
                        </Button>
                    </div>
                    <Table
                        components={{ body: { cell: EditableCell } }}
                        bordered
                        scroll={{ x: 'fit-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={(_, index) => `${index}`}
                        pagination={false}
                    />
                </div>
                <div>
                    <div>
                        <Checkbox
                            checked={otherChecked}
                            onChange={(e) => {
                                setOtherChecked(e.target.checked);
                                if (e.target.checked) {
                                    if (
                                        couponType === 'other' &&
                                        !isEmpty(dataOtherSource) &&
                                        !isEqual(props.value.other, dataOtherSource)
                                    ) {
                                        const nVal: any = cloneDeep(dataOtherSource) || [];
                                        props.onChange(
                                            {
                                                ...props.value,
                                                other: nVal.concat([...selectedRows]),
                                            },
                                            props.name
                                        );
                                    }
                                } else {
                                    props.onChange(
                                        {
                                            ...props.value,
                                            other: [],
                                        },
                                        props.name
                                    );
                                }
                            }}
                        />
                        <span style={{ marginRight: '8px' }}>
                            {language.getText('salesPromotion.eventMarketing.otherSideNote')}
                        </span>
                        <Button
                            type='primary'
                            style={{ marginBottom: '8px' }}
                            onClick={() => {
                                setCouponList([]);
                                setVisible(true);
                                setCouponType(() => {
                                    getCouponList('other');
                                    return 'other';
                                });
                            }}
                        >
                            {language.getText('salesPromotion.eventMarketing.addOtherCoupons')}
                        </Button>
                    </div>
                    <Table
                        components={{ body: { cell: EditableCell } }}
                        bordered
                        scroll={{ x: 'fit-content' }}
                        dataSource={dataOtherSource}
                        columns={otherColumns}
                        rowKey={(_) => `${_.id}`}
                        pagination={false}
                    />
                </div>
                <AntModal
                    width={800}
                    title={
                        couponType === 'coupon'
                            ? language.getText(
                                  'salesPromotion.eventMarketing.matchArrangement.couponsListTitle'
                              )
                            : language.getText(
                                  'salesPromotion.eventMarketing.matchArrangement.otherListTitle'
                              )
                    }
                    visible={visible}
                    confirmLoading={confirmLoading}
                    // afterClose={handleClose}
                    onCancel={handleCancel}
                    footer={[
                        <AntButton type='primary' onClick={handleComplete}>
                            {language.getText('salesPromotion.eventMarketing.achieve')}
                        </AntButton>,
                    ]}
                    destroyOnClose={true}
                >
                    {getAddForm()}
                    <Table
                        key={couponType}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                        }}
                        columns={couponListColumns}
                        dataSource={couponList}
                        rowKey={(_) => `${_.id}`}
                        pagination={couponList?.length > 10 ? undefined : false}
                    />
                </AntModal>
            </EditableContext.Provider>
        </>
    );
};
export class EventMarketingItemsMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
            mode: 'multiple',
        };
        return <EventMarketingItems {...props} />;
    }
}

export class EventMarketingItemsFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, _value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: object | undefined) {
        return { [key]: value };
    }
}
