import { APPLY_TYPE_VALUES, REWARD_TYPE_VALUES } from '@/constants';
import { fieldsToLanguageData, languageDataToFields } from '@/services/language-utils';
import { services } from '@comall-backend-builder/core';
import { isArray, isEmpty } from 'lodash';
import lodashGet from 'lodash/get';

const LANGUAGE_KEY = ['titleGift', 'promotionLabel', 'detailMap', 'clauseGoods', 'changeTitle'];

/** 处理提交参数 */
const dataToParams = (data: any) => {
    const getTarget = (type: 'applyType' | 'excludeType') => {
        const keyMap = {
            applyType: { target: 'applyTarget', value: 'apply' },
            excludeType: { target: 'excludeTarget', value: 'exclude' },
        };
        const target = keyMap[type]['target'];
        const val = keyMap[type]['value'];

        const toString = (v: any) => v?.join(',');

        switch (data[type]) {
            case APPLY_TYPE_VALUES.GOODS:
                return { [target]: data[`${val}Goods`] };
            case APPLY_TYPE_VALUES.BRAND:
                return { [target]: toString(data[`${val}Brand`]) };
            case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
                return { [target]: toString(data[`${val}OfflineCategory`]) };
            case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
                return { [target]: toString(data[`${val}VirtualCategory`]) };
            case APPLY_TYPE_VALUES.MERCHANT:
                return { [target]: toString(data[`${val}Merchant`]) };
            default:
                return { [target]: '' };
        }
    };

    // 会员等级
    if (!isEmpty(data.memberLevelIds)) {
        data.memberLevelIds = data.memberLevelIds.toString();
    } else {
        delete data.memberLevelIds;
    }
    if (data.rewardType === REWARD_TYPE_VALUES.MANY_PIECES) {
        data.fullNumber = [
            {
                sort: 0,
                conditionValue: data.conditionValue,
                prefrValue: data.prefrValue,
                title: services.language.getText('salesPromotion.conditionsReward.manyPieces'),
            },
        ];

        delete data.conditionValue;
        delete data.prefrValue;
    }

    const params = {
        ...data,
        storeIds: data.storeIds.map((item: string) => ({ id: item })),
        ...getTarget('applyType'),
        ...getTarget('excludeType'),
        endDate: lodashGet(data, 'effectTime.end'),
        startDate: lodashGet(data, 'effectTime.start'),
        discRule: '',
        // 换购件数
        prefrCount:
            data.rewardType === REWARD_TYPE_VALUES.CHOOSE_PRODUCT ? data.freeGiftThresholdNums : '',
        // 换购价格
        conditionPrice:
            data.rewardType === REWARD_TYPE_VALUES.CHOOSE_PRODUCT
                ? data.freeGiftThresholdPrice
                : '',
        swapSku: (data.exchangeProduct || []).map((item: any) => ({
            ...item,
            ...item.product,
            swapClause: String(item.styleCommodityTerms),
        })),
        discValue: lodashGet(data, 'discRule[0].reward') || lodashGet(data, 'discValue[0].reward'),
    };

    LANGUAGE_KEY.forEach((key) => {
        params[key] = fieldsToLanguageData(key, params);
    });

    return params;
};

const formatTime = (data: any) => {
    if (data.startDate && data.endDate) {
        return {
            effectTime: {
                start: data.startDate,
                end: data.endDate,
            },
        };
    }
    return {
        effectTime: null,
    };
};
const paramsToDate = (params: any) => {
    [
        'excludeType',
        'applyType',
        'rewardType',
        'showType',
        'swap',
        'conditionType',
        'type',
        'prefrCount',
        'conditionPrice',
        'freeGiftThresholdPrice',
        'freeGiftThresholdNums',
    ].forEach((i) => (params[i] = String(params[i])));
    const getTarget = (type: 'applyType' | 'excludeType') => {
        const keyMap = {
            applyType: { target: 'applyTarget', value: 'apply' },
            excludeType: { target: 'excludeTarget', value: 'exclude' },
        };
        const target = keyMap[type]['target'];
        const val = keyMap[type]['value'];

        const toArray = (v: any) => v?.split(',');

        switch (params[type]) {
            case APPLY_TYPE_VALUES.GOODS:
                return { [`${val}Goods`]: params[target] };
            case APPLY_TYPE_VALUES.BRAND:
                return { [`${val}Brand`]: toArray(params[target]) };
            case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
                return { [`${val}OfflineCategory`]: toArray(params[target]) };
            case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
                return { [`${val}VirtualCategory`]: toArray(params[target]) };
            case APPLY_TYPE_VALUES.MERCHANT:
                return { [`${val}Merchant`]: toArray(params[target]) };
            default:
                return {};
        }
    };

    LANGUAGE_KEY.forEach((language) => {
        languageDataToFields(language, params, params[language]);
    });

    // 会员等级
    if (!isEmpty(params.memberLevels)) {
        params.memberLevelIds = params.memberLevels.map(
            (item: { id: string; name: string }) => item.id
        );
    }
    if (params.rewardType === REWARD_TYPE_VALUES.MANY_PIECES) {
        params.prefrValue = params.fullNumber[0].prefrValue;
        params.conditionValue = params.fullNumber[0].conditionValue;
        delete params.fullNumber;
    }

    return {
        ...params,
        ...getTarget('applyType'),
        ...getTarget('excludeType'),
        ...formatTime(params),
        storeIds: params.storeIds.map((item: AnyObject) => item.id),
        exchangeProduct: isArray(params.swapSku)
            ? params.swapSku.map((item: any) => {
                  const {
                      skuClauseEn,
                      skuClauseTc,
                      skuClauseZh,
                      swapClause,
                      swapSelect,
                      exchangePurchasePrice,
                      ...product
                  } = item;
                  return {
                      product,
                      exchangePurchasePrice,
                      skuClauseEn,
                      skuClauseTc,
                      skuClauseZh,
                      swapSelect: String(swapSelect),
                      styleCommodityTerms: String(swapClause),
                  };
              })
            : [],
        // 换购件数
        freeGiftThresholdNums:
            params.rewardType === REWARD_TYPE_VALUES.CHOOSE_PRODUCT
                ? params.prefrCount
                : params.freeGiftThresholdNums,
        // 换购价格f
        freeGiftThresholdPrice:
            params.rewardType === REWARD_TYPE_VALUES.CHOOSE_PRODUCT
                ? params.conditionPrice
                : params.freeGiftThresholdPrice,
        discValue: params['discValue'] ? [{ reward: params['discValue'] }] : null,
        discRule: params['discValue'] ? [{ reward: params['discValue'] }] : null,
    };
};
export default [
    {
        key: '/dc-promotion/admin/promotion',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                return paramsToDate(response);
            },
            post: async function(data: any, config: any) {
                const params = dataToParams(data);
                return await services.api.post(params, config);
            },
            put: async function(data: any, config: any) {
                const params = dataToParams(data);

                return await services.api.put(params, {
                    ...config,
                    apiPath: `/dc-promotion/admin/promotion/edit`,
                });
            },
        },
    },
    {
        key: '/dc-promotion/admin/promotions/promotionRetailStore',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                // const result = response.map((item: any) => {
                //     return { ...item, name: item.retailStoreName };
                // });
                return response;
            },
        },
    },
];
