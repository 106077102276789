// 扣减商品库存form
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import {
    Button as AntdButton,
    Form,
    InputNumber,
    Table,
    Row as AntdRow,
    Col as AntdCol,
    message as AntdMessage,
    Input,
    Pagination,
} from 'antd';
import { findIndex, isEmpty } from 'lodash';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { DESIGN_MANAGE_LANGUAGE_NAMES, DESIGN_MANAGE_LANGUAGE_VALUES } from '@/constants';

interface DeductionsProps {
    params: any;
    entity: Entity;
    componentId: string;
    tableConfig: any;
    rowsData: any;
    selectedRowKeys: Array<string>;
}
const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

export const DeductionsForm: React.FC<DeductionsProps> = (props: any) => {
    const formRef = useRef(props.form);
    const [pageSize, setPageSize] = useState<any>('10');
    const [current, setCurrent] = useState<any>(1);
    const [dataSource, setDataSource] = useState<any>([]);
    const [commonSource, setCommonSource] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [details, setDetails] = useState<any>({});
    const [batchAndQuantityList, setBatchAndQuantityList] = useState<any>([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const renderContent = (value: any, row: any, index: number) => {
        const obj: any = {
            children: value,
            props: {},
        };
        if (row.stockBatchInfoList?.length !== 1) {
            obj.props.rowSpan =
                row.batchNo === row.stockBatchInfoList[0].batchNo
                    ? row.stockBatchInfoList?.length
                    : 0;
        }
        return obj;
    };
    // 组合商品子商品columns
    const columns = [
        {
            title: services.language.getText('productInventory.childProductName'),
            dataIndex: 'name',
            key: 'name',
            render: renderContent,
        },
        {
            title: 'SKU',
            dataIndex: 'csku',
            key: 'csku',
            render: renderContent,
        },
        {
            title: services.language.getText('productInventory.groupNumber'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: renderContent,
        },
        {
            title: services.language.getText('productInventory.batchNo'),
            dataIndex: 'batchNo',
            key: 'batchNo',
        },
        {
            title: services.language.getText('productInventory.surplusQuantity'),
            dataIndex: 'surplusQuantity',
            key: 'surplusQuantity',
        },
        {
            title: 'MCS Location',
            dataIndex: 'mcsLocation',
            key: 'mcsLocation',
        },
        {
            title: services.language.getText('productInventory.deductions'),
            dataIndex: 'status',
            key: 'delete',
            render: (key: any, record: any) => (
                <InputNumber
                    placeholder={services.language.getText('defaultPlaceholderInput')}
                    onChange={(value) => inputChange(value, record)}
                    value={
                        batchAndQuantityList.find(
                            (item: { batchNo: any }) => item.batchNo === record.batchNo
                        )?.useQuantity
                    }
                    max={99999999}
                    min={0}
                />
            ),
        },
    ];
    // 普通商品 批次columns
    const columns2 = [
        {
            title: services.language.getText('productInventory.batchNo'),
            dataIndex: 'batchNo',
            key: 'batchNo',
        },
        {
            title: services.language.getText('productInventory.batchTotalQuantity'),
            dataIndex: 'batchSurplusQuantity',
            key: 'batchSurplusQuantity',
        },
        {
            title: services.language.getText('productInventory.reserveQuantity'),
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
        },
        {
            title: services.language.getText('productInventory.expirationDate'),
            dataIndex: 'time',
            key: 'time',
            render: (key: any, row: any) => `${row.warrantyPeriodStart}-${row.warrantyPeriodEnd}`,
        },
        {
            title: 'MCS Location',
            dataIndex: 'mcsLocation',
            key: 'mcsLocation',
        },
        {
            title: services.language.getText('productInventory.deductionsNumber'),
            dataIndex: 'status',
            key: 'delete',
            width: 125,
            render: (key: any, record: any) => (
                <InputNumber
                    placeholder={services.language.getText('defaultPlaceholderInput')}
                    onChange={(value) => inputChange(value, record)}
                    value={
                        batchAndQuantityList.find(
                            (item: { batchNo: any }) => item.batchNo === record.batchNo
                        )?.useQuantity
                    }
                    max={99999999}
                    min={0}
                />
            ),
        },
    ];

    useEffect(() => {
        services.api
            .get(
                { csku: props.params.csku, storeId: props.params.storeId, rsku: props.params.rsku },
                {
                    apiPath: `/dc-stock/admin/rskus/stock/findStockEditDetail`,
                }
            )
            .then((res: any) => {
                setDetails(res);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (details.source === '2') {
            // 获取组合商品 子商品列表
            services.api
                .post(
                    {},
                    {
                        apiPath: `/dc-stock/admin/rskus/stock/findMcsStockBatchCombList?csku=${encodeURIComponent(
                            details.csku
                        )}&storeId=${props.params.storeId}&type=2`,
                    }
                )
                .then((res: any) => {
                    setData(res);
                });
        } else {
            // 获取商品批次dataSource
            if (!isEmpty(details))
                services.api
                    .post(
                        {},
                        {
                            apiPath: `/dc-stock/admin/rskus/stock/findMcsStockBatchList?csku=${encodeURIComponent(
                                details.csku
                            )}&source=${details.source}&rsku=${details.rsku}&storeId=${
                                props.params.storeId
                            }&page=${current}&per_page=${pageSize}`,
                        }
                    )
                    .then((res: any) => {
                        setData(res);
                    });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, current, pageSize]);

    useEffect(() => {
        if (!isEmpty(data)) {
            let dataList: any[] = [];
            if (details.source === '2') {
                data.forEach((item: any) => {
                    item.stockBatchInfoList.forEach((value: any) => {
                        dataList.push({
                            ...item,
                            ...value,
                        });
                    });
                });
                setDataSource(dataList);
            } else {
                setCommonSource(data.result);
            }
        }
    }, [data, details]);

    const inputChange = (value: any, record: any) => {
        setBatchAndQuantityList((list: any) => {
            const current = findIndex(list, (item: any) => item.batchNo === record.batchNo);
            if (current < 0) {
                return [...list, { ...record, useQuantity: value }];
            }
            list[current] = { ...record, useQuantity: value };

            return [...list];
        });
    };
    const onPaginationChange = (page: number, pageSize: number | undefined) => {
        setCurrent(page);
        setPageSize(pageSize);
    };
    const showTotal = (total: any) => {
        return interpolate(services.language.getText('table.total'), {
            total: total,
        });
    };
    const submit = useCallback(() => {
        setSubmitLoading(true);
        const {
            history: { goBack },
        } = props;
        let post: any;
        formRef.current.validateFields((err: any, values: any) => {
            if (details.source === '2') {
                post = {
                    batchAndQuantityList: batchAndQuantityList.filter(
                        (item: any) => item?.useQuantity
                    ),
                    csku: details.csku,
                    deducetReason: values.deducetReason,
                    operateSource: 1,
                    rsku: details.rsku,
                    source: details.source,
                    storeId: props.params.storeId,
                };
            } else {
                post = {
                    batchAndQuantityList: batchAndQuantityList.filter(
                        (item: any) => item?.useQuantity
                    ),
                    csku: details.csku,
                    deducetReason: values.deducetReason,
                    operateSource: 1,
                    rsku: details.rsku,
                    source: details.source,
                    storeId: props.params.storeId,
                };
            }
            if (!err) {
                services.api
                    .post(
                        {
                            ...post,
                        },
                        {
                            apiPath: `/dc-stock/admin/rskus/stock/deduct`,
                        }
                    )
                    .then((res) => {
                        AntdMessage.success(services.language.getText('successFully'));
                        goBack();
                    })
                    .catch((e) => {
                        services.errorHandle(e);
                    })
                    .finally(() => {
                        setSubmitLoading(false);
                    });
            } else {
                setSubmitLoading(false);
            }
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchAndQuantityList]);

    return (
        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 10,
            }}
        >
            {contentMap.map((item, index) => (
                <Form.Item
                    label={`${services.language.getText('productInventory.productName')}（${
                        DESIGN_MANAGE_LANGUAGE_NAMES[`${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES]
                    }）`}
                    key={index}
                >
                    {details.productLanguageName && (
                        <div>{details.productLanguageName[item.key]}</div>
                    )}
                </Form.Item>
            ))}
            <Form.Item label={services.language.getText('productInventory.productSKU')}>
                <div>{details.csku}</div>
            </Form.Item>
            <Form.Item label='goodsID'>
                <div>{details.goodsId}</div>
            </Form.Item>
            <Form.Item label={services.language.getText('productInventory.sourceType')}>
                <div>
                    {details.source === '2'
                        ? services.language.getText('productInventory.type.group')
                        : details.source === '1'
                        ? services.language.getText('productInventory.type.create')
                        : services.language.getText('productInventory.type.normal')}
                </div>
            </Form.Item>
            <Form.Item label={services.language.getText('productInventory.specsType')}>
                <div>
                    {details.specsType === '1'
                        ? services.language.getText('productInventory.weighing')
                        : services.language.getText('productInventory.common')}
                </div>
            </Form.Item>
            <Form.Item label={services.language.getText('productInventory.deducetReason')}>
                {props.form.getFieldDecorator('deducetReason')(
                    <Input
                        placeholder={services.language.getText('defaultPlaceholderInput')}
                        maxLength={100}
                    />
                )}
            </Form.Item>
            {details.source === '2' ? (
                <>
                    <Form.Item
                        wrapperCol={{ span: 18, offset: 4 }}
                        label={services.language.getText('productInventory.groupProduct')}
                    >
                        <Table
                            style={{
                                marginTop: 50,
                            }}
                            columns={columns}
                            dataSource={dataSource}
                            rowKey={(row) => row.batchNo}
                            bordered
                            pagination={false}
                        />
                    </Form.Item>
                </>
            ) : (
                <>
                    <Form.Item wrapperCol={{ span: 18, offset: 4 }}>
                        <Table
                            style={{
                                marginTop: 25,
                            }}
                            columns={columns2}
                            dataSource={commonSource}
                            rowKey={(row) => row.batchNo}
                            bordered
                            pagination={false}
                        />
                        <Pagination
                            style={{
                                marginTop: 30,
                                textAlign: 'right',
                            }}
                            total={data.totalNum}
                            showSizeChanger
                            showQuickJumper
                            pageSizeOptions={['10', '15', '20']}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            current={current}
                            onChange={(page, pageSize) => onPaginationChange(page, pageSize)}
                            onShowSizeChange={(current, page) => onPaginationChange(current, page)}
                            showTotal={(total) => showTotal(total)}
                        />
                    </Form.Item>
                </>
            )}
            <AntdRow>
                <AntdCol span={4}></AntdCol>
                <AntdCol span={16}>
                    <AntdRow
                        style={{
                            marginTop: 30,
                            textAlign: 'right',
                        }}
                    >
                        <AntdButton
                            style={{
                                marginRight: 10,
                            }}
                            onClick={() => {
                                props.history.goBack();
                            }}
                        >
                            {services.language.getText('cancel')}
                        </AntdButton>
                        <AntdButton type='primary' onClick={submit} loading={submitLoading}>
                            {services.language.getText('submit')}
                        </AntdButton>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
        </Form>
    );
};
export const ProductInventoryDeductionsForm = Form.create()(DeductionsForm);
