/**
 * 补充商品库存
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import {
    Button as AntdButton,
    Form,
    InputNumber,
    Select,
    Table,
    Row as AntdRow,
    Col as AntdCol,
    message as AntdMessage,
} from 'antd';
import { findIndex, get } from 'lodash';
import {
    PRODUCT_SOURCE_TYPE_NAMES,
    SPECS_TYPE_NAMES,
    PRODUCT_SOURCE_TYPE_VALUES,
    DESIGN_MANAGE_LANGUAGE_NAMES,
    DESIGN_MANAGE_LANGUAGE_VALUES,
} from '@/constants';
import { DateTimeRangePicker } from '@comall-backend-builder/components-basis';
const { Option } = Select;

interface SupplementProps {
    params: any;
    entity: Entity;
    componentId: string;
    tableConfig: any;
    rowsData: any;
    selectedRowKeys: Array<string>;
}
const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

export const SupplementForm: React.FC<SupplementProps> = (props: any) => {
    const formRef = useRef(props.form);
    const [dataSource, setDataSource] = useState<any>([]);

    const [details, setDetails] = useState<any>({});
    const [selectMcs, setSelectMcs] = useState<any>([]);
    const [batchAndQuantityList, setBatchAndQuantityList] = useState<any>([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    // 组合商品子商品columns渲染
    const renderContent = (value: any, row: any, index: number) => {
        const obj: any = {
            children: value,
            props: {},
        };
        if (row.stockBatchInfoList?.length !== 1) {
            obj.props.rowSpan =
                row.batchNo === row.stockBatchInfoList[0].batchNo
                    ? row.stockBatchInfoList?.length
                    : 0;
        }
        return obj;
    };
    // 组合商品子商品columns
    const columns = [
        {
            title: services.language.getText('productInventory.childProductName'),
            dataIndex: 'name',
            key: 'name',
            render: renderContent,
        },
        {
            title: 'SKU',
            dataIndex: 'csku',
            key: 'csku',
            render: renderContent,
        },
        {
            title: services.language.getText('productInventory.groupNumber'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: renderContent,
        },
        {
            title: services.language.getText('productInventory.batchNo'),
            dataIndex: 'batchNo',
            key: 'batchNo',
        },
        {
            title: services.language.getText('productInventory.surplusQuantity'),
            dataIndex: 'surplusQuantity',
            key: 'surplusQuantity',
        },
        {
            title: 'MCS Location',
            dataIndex: 'mcsLocation',
            key: 'mcsLocation',
        },
        {
            title: services.language.getText('productInventory.supplement'),
            dataIndex: 'status',
            key: 'add',
            render: (key: any, record: any) => (
                <InputNumber
                    placeholder={services.language.getText('defaultPlaceholderInput')}
                    onChange={(value) => inputChange(value, record)}
                    value={
                        batchAndQuantityList.find(
                            (item: { batchNo: any }) => item.batchNo === record.batchNo
                        )?.useQuantity
                    }
                    max={99999999}
                    min={0}
                />
            ),
        },
        {
            title: services.language.getText('actions'),
            dataIndex: 'option',
            key: 'option',
            render: (key: any, record: any) => {
                return (
                    <AntdButton type='link' onClick={() => inputChange('', record)}>
                        {services.language.getText('delete')}
                    </AntdButton>
                );
            },
        },
    ];
    // 获取库存归属MCS Location
    const getMcsLocation = async () => {
        try {
            const res = await services.api.get(
                {},
                {
                    apiPath: `/dc-store/admin/onlineStore/getMcsLocation/${props.params.storeId}`,
                }
            );

            setSelectMcs(res || []);
        } catch (err) {}
    };
    // 组合商品子商品dataSource
    const getCombList = async () => {
        try {
            const res: any[] = await services.api.post(
                {},
                {
                    apiPath: `/dc-stock/admin/rskus/stock/findMcsStockBatchCombList?csku=${encodeURIComponent(
                        props.params.csku
                    )}&storeId=${props.params.storeId}&type=1`,
                }
            );

            let dataList: any[] = [];
            (res || []).forEach((item: any) => {
                item.stockBatchInfoList.forEach((value: any) => {
                    dataList.push({
                        ...item,
                        ...value,
                    });
                });
            });

            setDataSource(dataList);
        } catch (e) {}
    };
    // 获取补充商品库存详情
    const getDetail = async () => {
        try {
            const res = await services.api.get(
                { csku: props.params.csku, storeId: props.params.storeId, rsku: props.params.rsku },
                {
                    apiPath: `/dc-stock/admin/rskus/stock/findStockEditDetail`,
                }
            );
            setDetails(res);
        } catch (e) {}
    };
    useEffect(() => {
        getMcsLocation();
        getCombList();
        getDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const inputChange = (value: any, record: any) => {
        setBatchAndQuantityList((list: any) => {
            const current = findIndex(list, (item: any) => item.batchNo === record.batchNo);
            if (current < 0) {
                return [...list, { ...record, useQuantity: value }];
            }
            list[current] = { ...record, useQuantity: value };

            const filteredList = list.filter(
                (item: any) => item.useQuantity !== undefined && item.useQuantity !== null
            );
            return filteredList;
        });
    };
    const submit = useCallback(() => {
        setSubmitLoading(true);
        const {
            history: { goBack },
        } = props;
        let post: any;
        formRef.current.validateFields((err: any, values: any) => {
            if (details.source === '2') {
                post = {
                    csku: details.csku,
                    operateSource: 1,
                    rsku: details.rsku,
                    source: details.source,
                    batchAndQuantityList: batchAndQuantityList,
                    storeId: props.params.storeId,
                };
            } else {
                post = {
                    csku: details.csku,
                    operateSource: 1,
                    rsku: details.rsku,
                    source: details.source,
                    mcsStockBatchQuantity: values.mcsStockBatchQuantity,
                    warrantyPeriodStart: values.time?.start,
                    warrantyPeriodEnd: values.time?.end,
                    mcsLocation: values.mcsLocation,
                    storeId: props.params.storeId,
                };
            }
            if (!err) {
                services.api
                    .post(
                        {
                            ...post,
                        },
                        {
                            apiPath: `/dc-stock/admin/rskus/stock/supply`,
                        }
                    )
                    .then((res) => {
                        AntdMessage.success(services.language.getText('successFully'));
                        goBack();
                    })
                    .catch((e) => {
                        services.errorHandle(e);
                    })
                    .finally(() => {
                        setSubmitLoading(false);
                    });
            } else {
                setSubmitLoading(false);
            }
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchAndQuantityList, details]);

    return (
        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 10,
            }}
        >
            {/* 商品名称 */}
            {contentMap.map((item, index) => (
                <Form.Item
                    label={`${services.language.getText('productInventory.productName')}（${
                        DESIGN_MANAGE_LANGUAGE_NAMES[`${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES]
                    }）`}
                    key={index}
                >
                    {details.productLanguageName && (
                        <div>{details.productLanguageName[item.key]}</div>
                    )}
                </Form.Item>
            ))}
            {/* csku */}
            <Form.Item label={services.language.getText('productInventory.productSKU')}>
                {details.csku}
            </Form.Item>
            {/* goodsId */}
            <Form.Item label='goodsID'>{details.goodsId}</Form.Item>
            {/* 商品来源 */}
            <Form.Item label={services.language.getText('productInventory.sourceType')}>
                {get(PRODUCT_SOURCE_TYPE_NAMES, get(details, 'source'))}
            </Form.Item>
            {/* 商品规格 */}
            <Form.Item label={services.language.getText('productInventory.specsType')}>
                {get(SPECS_TYPE_NAMES, get(details, 'specsType'))}
            </Form.Item>
            {details.source === PRODUCT_SOURCE_TYPE_VALUES.COMBINATION.toString() ? (
                <>
                    <Form.Item label={services.language.getText('productInventory.groupProduct')}>
                        <Table
                            style={{
                                width: 950,
                                marginLeft: -70,
                                marginTop: 50,
                            }}
                            columns={columns}
                            dataSource={dataSource}
                            rowKey={(row) => row.batchNo}
                            pagination={false}
                            bordered
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginLeft: 240,
                        }}
                    ></Form.Item>
                </>
            ) : (
                <>
                    <Form.Item label={services.language.getText('productInventory.supplement')}>
                        {props.form.getFieldDecorator('mcsStockBatchQuantity', {
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText(
                                        'productInventory.supplementMessage'
                                    ),
                                },
                            ],
                        })(
                            <InputNumber
                                max={99999999}
                                placeholder={services.language.getText('defaultPlaceholderInput')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('productInventory.expirationDate')}>
                        {props.form.getFieldDecorator('time')(
                            <DateTimeRangePicker name={''}></DateTimeRangePicker>
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('productInventory.attribution')}>
                        {props.form.getFieldDecorator('mcsLocation', {
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('defaultPlaceholderInput'),
                                },
                            ],
                        })(
                            <Select
                                placeholder={services.language.getText('defaultPlaceholderInput')}
                            >
                                {selectMcs.map((item: any) => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </>
            )}
            <AntdRow>
                <AntdCol span={4}></AntdCol>
                <AntdCol span={16}>
                    <AntdRow
                        style={{
                            marginTop: 30,
                            textAlign: 'right',
                        }}
                    >
                        <AntdButton
                            style={{
                                marginRight: 10,
                            }}
                            onClick={() => {
                                props.history.goBack();
                            }}
                        >
                            {services.language.getText('cancel')}
                        </AntdButton>
                        <AntdButton type='primary' onClick={submit} loading={submitLoading}>
                            {services.language.getText('submit')}
                        </AntdButton>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
        </Form>
    );
};
export const ProductInventorySupplementForm = Form.create()(SupplementForm);
