import { services } from '@comall-backend-builder/core/lib';

export default [
    {
        // 积分列表
        key: '/dc-user/admin/members/getMemberPointList',
        loader: {
            get: async function(data: any, config: any) {
                const { time } = data;

                if (time) {
                    data.createBeginTime = time.start;
                    data.createEndTime = time.end;
                    delete data.time;
                }
                return await services.api.get(data, config);
            },
        },
    },
    {
        // 区号查询
        key: '/dc-cms-api/areaCodes/findAll',
        loader: {
            get: async function(data: any, config: any) {
                const response: any[] = await services.api.get(data, config);
                return (response || []).map((item: any) => ({ ...item, name: item.code }));
            },
        },
    },
    {
        // 会员优惠券查询
        key: '/dc-ecoupon/admin/vouchers/findMemeberVoucherPage',
        loader: {
            get: async function(data: any, config: any) {
                const params = { ...data, memberId: data.id };
                delete params.id;
                const response = await services.api.get(params, config);
                return response;
            },
        },
    },
    {
        key: '/dc-user/admin/members/getMemberCouponList',
        loader: {
            get: async function(data: any, config: any) {
                const params = { ...data, memberId: data.id };
                delete params.id;
                const response = await services.api.get(params, config);
                return response;
            },
        },
    },
    {
        key: '/dc-user/admin/members',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    return services.api
                        .get(data, {
                            ...config,
                            apiPath: '/dc-user/admin/members/' + data.id,
                        })
                        .then((response: any) => ({
                            ...response,
                            familySize: String(response.familySize),
                            isPush: String(response.isPush),
                            isCheck: String(response.isCheck),
                            memberType: String(response.memberType) === '2' ? '1' : '0',
                        }));
                }
            },
            put: async function(data: any, config: any) {
                services.api.put(
                    { ...data, memberType: String(data.memberType) === '1' ? '2' : '1' },
                    {
                        ...config,
                        apiPath: '/dc-user/admin/members/' + data.id,
                    }
                );
            },
        },
    },
    {
        key: '/dc-user/admin/memberLevel/level',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    return services.api
                        .get(data, {
                            ...config,
                            apiPath: '/dc-user/admin/memberLevel/level/' + data.id,
                        })
                        .then((response: any) => ({
                            ...response,
                        }));
                }
            },
        },
    },
];
