import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import {
    COUPON_STATUS_OPTIONS,
    COUPON_PROVIDE_TYPE_OPTIONS,
    PROVIDE_TYPE_OPTIONS,
    RELIEF_TYPE_OPTIONS,
    USE_TYPE_OPTIONS,
    DRAW_TYPE_OPTIONS,
    APPLY_TYPE_OPTIONS,
    EXCLUDE_TYPE_OPTIONS,
    ORDER_USE_TYPE_OPTIONS,
    EXCLUSION_TYPE_OPTIONS,
    COUPON_STATUS_VALUES_OPTIONS,
} from '@/constants';
import { buildForm } from './discount-coupon-form';
import { buildPropertiesByLanguage } from '@/services/language-utils';
import { get } from 'lodash';

export const config: Config = {
    entities: {
        discountCoupon: {
            apiPath: '/loader/discount-coupon',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.couponName.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                effectTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<couponManage.effectTime.title>>',
                },
                paymentModes: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<couponManage.paymentMode.title>>',
                    defaultValue: '',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/coupons/paymentMode',
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<common.pleaseSelect>>',
                    },
                },
                paymentCode: {
                    type: 'string',
                    displayName: '<<couponManage.paymentCode.title>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        allowClear: true,
                        maxLength: 50,
                    },
                },
                weekIds: {
                    type: 'array.optionIds.select',
                    displayName: '<<couponManage.weekIds.title>>',
                    options: [],
                    source: {
                        apiPath: '/dc-master-data/admin/week/list',
                    },
                    controlConfig: {
                        placeholder: '<<common.pleaseSelect>>',
                        allowClear: true,
                        mode: 'multiple',
                    },
                },
                effectStartTime: {
                    type: 'string',
                    displayName: '<<couponManage.effectTime.startTitle>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<couponManage.effectTime.endTitle>>',
                },
                provideType: {
                    type: 'string.options.radio',
                    options: PROVIDE_TYPE_OPTIONS,
                    displayName: '<<couponManage.provideType.title>>',
                },
                provideNum: {
                    type: 'number',
                    displayName: '<<couponManage.provideNum.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        max: 99999999,
                    },
                },
                storeIds: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.storeIds.title>>',
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                    },
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                        titles: [
                            '<<couponManage.storeIds.left>>',
                            '<<couponManage.storeIds.right>>',
                        ],
                        nameProp: 'onlineStoreName',
                    },
                },
                couponCode: {
                    type: 'string',
                    displayName: '<<couponManage.couponCode.title>>',
                    controlConfig: {
                        placeholder: '<<couponManage.couponCode.placeholder>>',
                    },
                },
                applyType: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.applyType.title>>',
                    options: APPLY_TYPE_OPTIONS,
                },
                applyTypeDisplay: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.applyType.display>>',
                    options: APPLY_TYPE_OPTIONS,
                },
                applyGoods: {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.applyType.goodsTitle>>',
                    controlConfig: {
                        placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                    },
                },
                applyBrand: {
                    type: 'array.optionIds.autoComplete',
                    displayName: '<<couponManage.applyType.brandTitle>>',
                    options: [],
                    source: {
                        apiPath: '/dc-goods/admin/brands/list',
                    },
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                },
                applyOfflineCategory: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath:
                                '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                        },
                    },
                },
                applyVirtualCategory: {
                    type: 'array.treeNodeIds.withParam',
                    displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        itemConfig: {
                            apiPath:
                                '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
                            formatProps: (props: any) => {
                                return {
                                    params: {
                                        onlineStoreIds: (
                                            get(props.entity, 'fields.storeIds') || []
                                        ).join(','),
                                    },
                                };
                            },
                        },
                    },
                },
                applyMerchant: {
                    type: 'array.optionIds.select',
                    displayName: '<<couponManage.applyType.merchantTitle>>',
                    options: [],
                    source: {
                        apiPath: '/dc-goods/admin/merchant/list',
                    },
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                },
                excludeType: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.excludeType.title>>',
                    options: EXCLUDE_TYPE_OPTIONS,
                },
                excludeGoods: {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.applyType.goodsTitle>>',
                    controlConfig: {
                        placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                    },
                },
                excludeBrand: {
                    type: 'array.optionIds.autoComplete',
                    displayName: '<<couponManage.applyType.brandTitle>>',
                    options: [],
                    source: {
                        apiPath: '/dc-goods/admin/brands/list',
                    },
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                },
                excludeOfflineCategory: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath:
                                '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                        },
                    },
                },
                excludeVirtualCategory: {
                    type: 'array.treeNodeIds.withParam',
                    displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        itemConfig: {
                            apiPath:
                                '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
                            formatProps: (props: any) => {
                                return {
                                    params: {
                                        onlineStoreIds: (
                                            get(props.entity, 'fields.storeIds') || []
                                        ).join(','),
                                    },
                                };
                            },
                        },
                    },
                },
                excludeMerchant: {
                    type: 'array.optionIds.select',
                    displayName: '<<couponManage.applyType.merchantTitle>>',
                    options: [],
                    source: {
                        apiPath: '/dc-goods/admin/merchant/list',
                    },
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                },
                reliefType: {
                    type: 'string.options.radio',
                    options: RELIEF_TYPE_OPTIONS,
                    displayName: '<<couponManage.reliefType.title>>',
                },
                reliefNum: {
                    type: 'number',
                    displayName: '<<couponManage.reliefNum.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                useType: {
                    type: 'string.options.radio',
                    options: USE_TYPE_OPTIONS,
                    displayName: '<<couponManage.useType.title>>',
                },
                useNum: {
                    type: 'number',
                    displayName: '<<couponManage.useNum.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                drawType: {
                    type: 'string.options.radio',
                    options: DRAW_TYPE_OPTIONS,
                    displayName: '<<couponManage.drawType.title>>',
                    controlConfig: {
                        style: {
                            width: 375,
                        },
                    },
                },
                drawNum: {
                    type: 'number',
                    displayName: '<<couponManage.drawNum.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                orderUseType: {
                    type: 'string.options.radio',
                    options: ORDER_USE_TYPE_OPTIONS,
                    displayName: '<<couponManage.orderUseType.title>>',
                    controlConfig: {
                        style: {
                            width: 375,
                        },
                    },
                },
                orderUseNum: {
                    type: 'number',
                    displayName: '<<couponManage.orderUseNum.title>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                exclusionType: {
                    type: 'string.options.radio',
                    options: EXCLUSION_TYPE_OPTIONS,
                    displayName: '<<couponManage.exclusionType.title>>',
                    controlConfig: {
                        style: {
                            width: 375,
                        },
                    },
                },
                ...buildPropertiesByLanguage('couponName', {
                    type: 'string',
                    displayName: '<<couponManage.couponName.display>>',
                }),
                ...buildPropertiesByLanguage('description', {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.description.title>>',
                }),
                couponPic: {
                    type: 'array.brandImage',
                    displayName: '<<couponManage.couponPic.title>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                stores: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.common.stores>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<couponManage.common.couponStatus>>',
                },
                expirationDay: {
                    type: 'number',
                    displayName: '<<couponManage.common.expirationDay>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                memberLevelIdList: {
                    type: 'array.optionIds.select',
                    displayName: '<<salesPromotion.conditionQualification.memberLevelIds>>',
                    options: [],
                    source: {
                        apiPath: '/dc-user/admin/memberLevel/findAllMemberLevelList',
                    },
                    controlConfig: {
                        placeholder: '<<common.pleaseSelect>>',
                        allowClear: true,
                        mode: 'multiple',
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponManage.common.couponName>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                storeIds: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<overrideCode.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                provideType: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.common.provideType.title>>',
                    options: COUPON_PROVIDE_TYPE_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.couponCenter.viewData.status>>',
                    options: COUPON_STATUS_VALUES_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        DiscountCouponView: {
            component: 'Viewport',
        },
        DiscountCouponPage: {
            entity: 'discountCoupon',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'name',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'storeIds',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'provideType',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'status',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<couponManage.common.addCoupon>>',
                            route: '/discount-coupon/add',
                            privilege: {
                                path: 'coupon.discount_coupon.discount_coupon_create',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'DiscountCouponTable',
                    privilege: {
                        path: 'coupon.discount_coupon.discount_coupon_list',
                        level: 'full',
                    },
                },
            ]),
        },
        DiscountCouponTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'effectStartTime',
                },
                {
                    property: 'effectEndTime',
                },
                {
                    property: 'reliefType',
                },
                {
                    property: 'provideType',
                },
                {
                    property: 'applyTypeDisplay',
                },
                {
                    property: 'stores',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: COUPON_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-ecoupon/admin/coupons/disable/{{row.id}}',
                                false: '/dc-ecoupon/admin/coupons/enable/{{row.id}}',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'coupon.discount_coupon.discount_coupon_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/discount-coupon/edit/{{row.id}}',
                        privilege: {
                            path: 'coupon.discount_coupon.discount_coupon_update',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<couponManage.common.viewData>>',
                        type: 'link',
                        path: '/discount-coupon/data/{{row.id}}',
                        privilege: {
                            path: 'coupon.discount_coupon.discount_coupon_detail',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        DiscountCouponAddPage: {
            component: 'Card',
            entity: 'discountCoupon',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        DiscountCouponEditPage: {
            component: 'Card',
            entity: 'discountCoupon',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};
